import React, { useState, useContext } from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import SEO from "./Seo"

import firebase from 'gatsby-plugin-firebase'
import { AuthContext } from '../context/auth'
import {navigate} from 'gatsby'

const Login = () => {
    const [data, setData] = useState({
        email: '',
        password: '',
        errorMessage: null,
    })
    const {setUser} = useContext(AuthContext)

    const handleChange = e => {
        const {name, value} = e.target
        setData({
            ...data, [name]:value
        })
    }

    const handleSubmit = async e => {
        e.preventDefault();
        setData({...data, errorMessage:null})
        try {
            const result = await firebase
                .auth()
                .signInWithEmailAndPassword(data.email, data.password)
            setUser(result)
            navigate("/app/cv")
        } catch (error) {
            console.log(error)
            setData({
                ...data, errorMessage:error.message
            })
        }
    }
    return (
        <>
        <SEO title="CV" />
        <div className="flex justify-center items-center mt-24 ">
            <form onSubmit={handleSubmit} className="sm:w-1/4 md:w-1/3 lg:w-1/3 w-4/5">
                <div>
                    <label htmlFor="email">Email</label>
                    <input type="email" name="email" autoComplete="off" value={data.email} onChange={handleChange} className="w-full border-b border-primary-300 mt-2 mb-8 focus:outline-none focus:border-primary-500" />
                </div>
                <div>
                    <label htmlFor="password">Password</label>
                    <input type="password" name="password" autoComplete="off" value={data.password} onChange={handleChange} className="w-full border-b border-primary-300 mt-2 mb-8 focus:outline-none focus:border-primary-500" />
            </div>
            {data.errorMessage ? <p style={{color:'red'}}>{data.errorMessage}</p>: ""}
            <div className="text-right py-4 pr-4">
                <button type="submit" className="bg-primary-50 py-1 px-4 rounded hover:bg-primary-100">Log In</button>        
            </div>
            </form>
        </div>

        <div className="flex justify-center">
            <p className="sm:text-baseline text-sm text-justify mt-8 mb-12 text-primary-500 ">
                    Please log in to see my full CV, or {` `}
                <AniLink to="/contact" cover bg="yellow" direction="up" className="text-primary-400 no-underline hover:text-primary-500" >contact me</AniLink><br />
                for the credentials. Thank you.
            </p>
        </div>
        </>
    )
}

export default Login;









// const {user} = useContext(AuthContext)************* in other components