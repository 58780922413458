import React from "react"
import SEO from "./Seo"

const FullCV = () => {

    return (
        <>
            <SEO title="CV" />
              <embed src={`${process.env.GATSBY_CV_PDF}`} type="application/pdf" width="100%" height="600px" />
        </>
    )
}

export default FullCV;
